@import "../../../../../node_modules/@ecom/open-components/src/styles/variable.less";
@import "../../../../../node_modules/@ecom/open-components/src/styles/mixin.less";
@import '../../util/tool';

@color: #12141a;
@color2: #85878a;

.partner-user {
  display: flex;
  color: @color;
  align-items: center;
  cursor: pointer;

  &-name {
    font-size: 14px;
    font-weight: 400;
    max-width: 200px;
    margin-left: 8px;
    line-height: 20px;
    max-height: 20px;
    word-break: break-all;
    overflow: hidden;
  }

  &-skeleton {
    margin-left: 8px;

    .auxo-skeleton-button {
      height: 20px;
      line-height: 20px;
    }
  }

  &-suffix {
    font-size: 12px;
    color: #85878a;
    margin: 0 8px 0 4px;
  }

  &-content {
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    width: 268px;
    color: @color;

    &-row {
      border-top: 1px solid #edeef0;

      &:first-child {
        border-top: 0;
      }
    }

    &-info {
      padding: 16px;
    }

    &-desc {
      font-size: 12px;
      font-weight: 500;
      .lineN(17px, 2);
    }

    &-tags {
      font-size: 0;
      &-tag {
        margin: 8px 4px 0 0 !important;
      }
    }

    &-menu {
      cursor: pointer;
      padding: 12px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-content {
        display: flex;
      }

      &-title {
        margin-left: 4px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }

      &-suffix {
        font-size: 12px;
        color: @color2;
      }
    }

    &-logout {
      cursor: pointer;
      padding: 12px 16px;
      text-align: center;
      color: @color2;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
