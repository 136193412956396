html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.auxo-form-item-explain {
  min-height: inherit !important;
}

/* 保护左上角logo，避免子应用篡改样式 */

.open-header-logo-img {
  display: inline;
  max-width: none;
}

/* 门户网站自定义样式 */

.door-custom-primary-button.auxo-btn-primary {
  box-shadow: 0 6px 18px rgba(38, 93, 237, .29);
}

/* 统一规范 */

.specs-open-table.ecom-open-table-wrapper {
  padding: 24px;
  border-radius: 8px;
}

.specs-open-panel.ecom-open-panel {
  border-radius: 8px;

  .ecom-open-panel-title {
    margin-bottom: 16px;
    color: #252931;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
  }
}

/* 通用的链接样式 */

.partner-workbench-link-style {
  cursor: pointer !important;

  &:hover {
    color: #4784ff !important;
  }

  &:active {
    color: #1556d9 !important;
  }
}

@font-face {
  font-family: Barlow;
  src: url("https://lf3-static.bytednsdoc.com/obj/eden-cn/phbozuldvbs/Barlow-SemiBold.ttf");
}
