@import "../../../../../node_modules/@ecom/open-components/src/styles/variable.less";
@import "../../../../../node_modules/@ecom/open-components/src/styles/mixin.less";
.noticeModal {
  :global {
    .auxo-modal-body {
      flex: 1 1 600px !important;
      padding-right: 0;
    }
  }
}

.noticeList {
  position: relative;
  margin-bottom: 26px;
  padding: 12px 16px 18px 20px;

  &:hover {
    background-color: #f6f6fa;
    border-radius: 4px;
  }

  .badge {
    position: absolute;
    left: 8px;
    width: 8px;
  }

  h3 {
    color: #12141a;
    font-size: 16px;
    line-height: 22px;
    height: 22px;
  }

  &.actived h3 {
    color: #55585c;
  }

  .richText {
    margin: 10px 8px 9px 0;
    line-height: 20px;
    color: #55585c;
  }

  .bottom {
    display: flex;
    margin-left: 0;
    justify-content: space-between;
  }

  time {
    color: #55585c;
    font-size: 14px;
    line-height: 20px;
  }
}

#scrollableDiv {
  height: 530px;
  overflow: auto;
  padding-right: 24px;
}

.noContent {
  text-align: center;
  margin-top: 160px;

  img {
    width: 240px;
    display: block;
    margin: 0 auto 16px;
  }

  span {
    color: #85878a;
    font-size: 14px;
    line-height: 20px;
  }
}

.shadow {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0), #fff);
  bottom: 24px;
  content: ' ';
  display: block;
  height: 24px;
  left: 0;
  position: absolute;
  width: 100%;
}

.notificationContent {
  display: inline-block;
  max-height: 200px;
  overflow-y: scroll;

  img {
    max-width: 100%;
  }
}

.richText {
  max-height: 200px;
  overflow-y: scroll;

  img {
    width: 100%;
  }
}
