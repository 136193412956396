@import "../../../../../node_modules/@ecom/open-components/src/styles/variable.less";
@import "../../../../../node_modules/@ecom/open-components/src/styles/mixin.less";
@horizontalPadding: 16px;
@siderWidth: 200px;

.partner-header {
  width: 100%;
  height: 64px;
  box-sizing: border-box;
  padding: 0 @horizontalPadding;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  background: transparent;

  &-fixed &-bg {
    opacity: 1;
    transition-timing-function: ease-in;
  }

  &-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #FFF;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    opacity: 0;
    transition: opacity 0.5s;
    transition-timing-function: ease-out;
  }

  &-logo {
    position: relative;
    width: @siderWidth;
    flex: none;
    font-size: 0;
  }

  &-content {
    margin-left: @horizontalPadding;
    width: 0;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-nav {
      display: flex;
      align-items: center;

      &-item {
        margin-left: 32px;

        &:first-child {
          margin-left: 18px;
        }
      }
    }

    &-func {
      display: flex;
      align-items: center;

      &-item {
        margin-left: 16px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
