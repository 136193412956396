@import "../../../../../../node_modules/@ecom/open-components/src/styles/variable.less";
@import "../../../../../../node_modules/@ecom/open-components/src/styles/mixin.less";
@import "../../../util/tool";

@primaryColor: #1966FF;
@primaryBg: rgba(72, 113, 246, 0.1);

.partner-sider-group {
  &-head {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &-icon {
      width: 20px;
      height: 20px;

      > img {
        width: 100%;
        height: 100%;
      }

      &-normal {
        display: block;
      }

      &-active {
        display: none;
      }
    }

    &-title {
      width: 124px;
      margin: 0 12px 0 4px;
      font-weight: 500;
      font-size: 14px;
      .line1(20px);
      color: #12141A;
    }

    &-suffix {
      font-size: 12px;
      color: #85878A;
    }
  }

  &-body {
    overflow: hidden;
    transition: height 0.5s;

    &-item {
      box-sizing: border-box;
      border-left: 2px solid transparent;
      padding: 10px 12px 10px 50px;
      font-weight: 400;
      font-size: 14px;
      .line1(20px);
      max-height: 40px;
      color: #55585C;
      cursor: pointer;

      &-active {
        border-left-color: @primaryColor;
        color: @primaryColor;
        font-weight: 500;
        background: @primaryBg;
      }

      &:hover {
        color: @primaryColor;
      }
    }
  }

  &-overlay {
    .auxo-popover-arrow {
      display: none;
    }

    .auxo-popover-inner {
      width: 140px;
      background: #FFF;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      overflow: hidden;
    }

    .auxo-popover-inner-content {
      padding: 0 !important;
    }

    &-item {
      box-sizing: border-box;
      border-left: 1.5px solid transparent;
      padding: 10px 12px 10px 22.5px;
      font-size: 14px;
      font-weight: 400;
      .line1(20px);
      max-height: 40px;
      color: #55585C;
      cursor: pointer;

      &-active {
        border-left-color: @primaryColor;
        color: @primaryColor;
        font-weight: 500;
        background: @primaryBg;
      }

      &:hover {
        color: @primaryColor;
      }
    }
  }

  &-active, &:hover {
    .partner-sider-group-head-icon {
      &-normal {
        display: none;
      }

      &-active {
        display: block;
      }
    }
  }
}
