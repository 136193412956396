@import "../../../node_modules/@ecom/open-components/src/styles/variable.less";
@import "../../../node_modules/@ecom/open-components/src/styles/mixin.less";
@import './fix';

@verticalPadding: 12px;
@horizontalPadding: 16px;

.partner-platform {
  background: #F3F4F6;

  &-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &-header {
    width: 100%;
    flex: none;
    position: relative;
    z-index: 102;
  }

  &-body {
    flex: 1;
    display: flex;
    height: 0;
  }

  &-sider {
    flex: none;
    box-sizing: border-box;
    height: 100%;
    padding: @verticalPadding 0 @verticalPadding @horizontalPadding;
    position: relative;
    z-index: 101;
  }

  &-content {
    flex: 1;
    position: relative;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding: @verticalPadding @horizontalPadding;
  }
}
