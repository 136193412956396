@import "../../../node_modules/@ecom/open-components/src/styles/variable.less";
@import "../../../node_modules/@ecom/open-components/src/styles/mixin.less";
@import './fix';

.logo {
  fill: #0a1833;
  margin-left: 8px;
  cursor: pointer;
}

.skeleton {
  :global {
    .auxo-skeleton-button {
      height: 20px;
      line-height: 20px;
    }
  }
}
