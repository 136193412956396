@import "../../../../../node_modules/@ecom/open-components/src/styles/variable.less";
@import "../../../../../node_modules/@ecom/open-components/src/styles/mixin.less";
.modal {
  :global {
    .auxo-modal-body {
      padding-top: 14px !important;
    }
  }
}

.image {
  display: block;
  width: 432px;
  margin: 0 auto 16px;
  border-radius: 2px;
}

.content {
  font-size: 14px;
  line-height: 20px;
}
