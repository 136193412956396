@import "../../../../../node_modules/@ecom/open-components/src/styles/variable.less";
@import "../../../../../node_modules/@ecom/open-components/src/styles/mixin.less";
@import '../../util/tool';

@iconWidth: 20px;
@iconRightMargin: 8px;
@primaryColor: #1966FF;

.partner-nav-item-menu-group {
  &-content {
    background: #FFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 20px;
    display: flex;


    &-column {
      margin-left: 60px;
      display: flex;
      flex-direction: column;

      &:first-child {
        margin-left: 0;
      }
    }

    &-group {
      display: flex;
      flex-direction: column;
      margin-top: 24px;

      &:first-child {
        margin-top: 0;
      }

      &-head {
        display: flex;
      }

      &-head &-text {
        font-weight: 500;
        color: #12141A !important;
      }

      &-icon {
        width: @iconWidth;
        height: @iconWidth;
        margin-right: @iconRightMargin;

        > img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      &-text {
        font-weight: 400;
        font-size: 14px;
        color: #55585C;
        min-width: 120px - @iconWidth - @iconRightMargin;
        max-width: 200px - @iconWidth - @iconRightMargin;
        .line1(20px);

        &:hover {
          color: @primaryColor;
        }
      }

      &-item {
        padding-left: @iconWidth + @iconRightMargin;
        margin-top: 12px;
        cursor: pointer;
      }

      &-active &-text {
        color: @primaryColor;
      }
    }
  }

  &-icon {
    font-size: 12px;
    margin-left: 4px;
    color: #85878A;
  }
}

.partner-nav-item {
  &:hover, &-active {
    .partner-nav-item-menu-group-icon{
      color: @primaryColor;
    }
  }
}
