@import "../../../../../node_modules/@ecom/open-components/src/styles/variable.less";
@import "../../../../../node_modules/@ecom/open-components/src/styles/mixin.less";
@primaryColor: #1966FF;

.partner-function {
  color: #12141A;
  display: flex;
  align-items: center;
  cursor: pointer;

  &-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 4px;
  }

  &:hover {
    color: @primaryColor;
    fill: @primaryColor;
  }
}
