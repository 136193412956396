@import "../../../../../node_modules/@ecom/open-components/src/styles/variable.less";
@import "../../../../../node_modules/@ecom/open-components/src/styles/mixin.less";
@import "../../util/tool";

.partner-sider {
  width: 200px;
  height: 100%;
  background: #FFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  transition: width .5s;

  &-fold {
    width: 52px;
  }

  &-head {
    height: 48px;
    box-sizing: border-box;
    border-bottom: 1px solid #EDEEF0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;

    &-title {
      margin: 0 12px;
      width: 128px;
      font-weight: 500;
      font-size: 16px;
      .line1(22px);
      color: #000;
    }

    &-fold {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  &-body {
    height: 0;
    flex: 1;
    overflow: auto;
    box-sizing: border-box;
    padding-bottom: 12px;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
