@import "../../../../../node_modules/@ecom/open-components/src/styles/variable.less";
@import "../../../../../node_modules/@ecom/open-components/src/styles/mixin.less";
.simplePagination {
  position: absolute;
  bottom: 25px;
  left: 54px;

  .turn {
    width: 16px;
    height: 16px;
    border: 1px solid #eeeff0;
    border-radius: 100%;
    margin: 0 8px;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    line-height: 12px;
    font-weight: bold;
    font-size: 14px;
    position: relative;

    &:last-child {
      padding-left: 1px;
    }
  }

  .first,
  .last {
    cursor: not-allowed;

    svg {

      path {
        fill: #aaabaf;
      }
    }
  }
}
