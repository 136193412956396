@import "../../../../../node_modules/@ecom/open-components/src/styles/variable.less";
@import "../../../../../node_modules/@ecom/open-components/src/styles/mixin.less";
// 使用全局样式避免子应用样式覆盖

.auxo-notification-notice.partner-notification {
  position: relative;
  width: 387px;
  padding: 24px;
  border-radius: 4px;

  .auxo-notification-notice-message {
    width: 306px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 36px;
  }

  .auxo-notification-notice-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #565960;
    margin-left: 38px;
  }
}

.open-user-message {

  .auxo-badge-count {
    min-width: 16px;
    height: 16px;
    line-height: 16px;
    padding: 0 3px;
  }
}
