.partner-platform-content {
  .ecom-open-page-container {
    min-height: calc(~"100vh - 88px");
    border-radius: 8px;
  }

  .ecom-open-page-layout-default, .ecom-open-page-layout-breadcrumb {
    margin: 0;
  }

  .ecom-open-breadcrumb {
    margin: 0 0 16px;
  }
}
