@import "../../../../../node_modules/@ecom/open-components/src/styles/variable.less";
@import "../../../../../node_modules/@ecom/open-components/src/styles/mixin.less";
.partner-layout {
  position: relative;
  overflow: auto;

  &-content {
    position: relative;
    margin: 0 auto;
    height: 100vh;
  }
}
