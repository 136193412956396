/*less工具函数*/

.line1(@lineHeight) {
  line-height: @lineHeight;
  max-height: @lineHeight;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}

.lineN(@lineHeight, @n) {
  line-height: @lineHeight;
  max-height: @lineHeight * @n;
  white-space: normal;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: @n;
  overflow: hidden;
}
