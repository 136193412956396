@import "../../../node_modules/@ecom/open-components/src/styles/variable.less";
@import "../../../node_modules/@ecom/open-components/src/styles/mixin.less";
.contractModal {
  :global(.auxo-modal-content) {
    max-height: none!important;
  }
}

.contractWrapper {
  width: 100%;
  height: 100%;
  position: relative;

  .contract {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: auto;
  }
}