@import "../../../../../node_modules/@ecom/open-components/src/styles/variable.less";
@import "../../../../../node_modules/@ecom/open-components/src/styles/mixin.less";
.header {
  width: 100%;
  min-width: 1200px;
  height: 72px;
  box-shadow: inset 0 -1px 0 rgba(233, 236, 242, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  transition: 1s;
}

.headerOpacity {
  background: rgba(255, 255, 255, 0.3);
}

.container {
  width: 1200px;
  display: flex;
  align-items: center;
}

.logo {
  > img {
    width: 210px;
    height: 36px;
  }
}

.menu {
  margin: 0 0 0 30px;
  padding: 0;
  list-style-type: none;
  flex: 1;
  align-items: center;
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.menuItem {
  padding: 0 24px;
  margin: 0;
  list-style-type: none;

  > a {
    color: #1e2945;
  }
}

.menuItemSelected {
  position: relative;

  ::after {
    content: '';
    display: inline-block;
    width: 16px;
    height: 4px;
    border-radius: 2px;
    position: absolute;
    left: calc(50% - 16px / 2);
    bottom: -8px;
    background: #1966ff;
  }

  > a {
    color: #1966ff;
  }
}

.user {
  display: flex;
  align-items: center;
}

.workbench {
  width: 102px;
  background: rgba(25, 102, 255, 0) !important;
}
