@import "../../../node_modules/@ecom/open-components/src/styles/variable.less";
@import "../../../node_modules/@ecom/open-components/src/styles/mixin.less";
.layout {
  min-width: 1366px;
}

.main {
  min-height: calc(100vh - 176px);
  background-color: #fff;
}
