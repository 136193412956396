@import "../../../../../node_modules/@ecom/open-components/src/styles/variable.less";
@import "../../../../../node_modules/@ecom/open-components/src/styles/mixin.less";
@activeColor: #1966FF;

.partner-nav-item {
  color: #12141A;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    color: @activeColor;
  }

  &-active {
    font-weight: 500;
    color: @activeColor;
  }
}
