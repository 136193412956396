@import "../../../../../../../node_modules/@ecom/open-components/src/styles/variable.less";
@import "../../../../../../../node_modules/@ecom/open-components/src/styles/mixin.less";
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 40px;
  position: relative;
  cursor: pointer;
  > img {
    object-fit: cover;
  }

  &::before {
    background: #d7dbe0;
    content: '';
    height: 100%;
    left: -20px;
    position: absolute;
    top: 0;
    width: 1px;
  }

  > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  > svg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.userDropdown {
  width: 240px;
  background: #fff;
  border: 1px solid #e9ecf2;
  box-shadow: 0 4px 10px rgba(41, 43, 51, 0.05);
  border-radius: 4px;
}

.userInfo {
  padding: 16px;
  display: flex;
  align-items: center;

  .userAvatar {
    margin-right: 16px;
    width: 52px;
    height: 52px;
    border-radius: 50%;

    > img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .userName {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #292b33;
    margin-bottom: 4px;
  }

  .certified {
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #37c371;

    > svg {
      margin-right: 4px;
    }
  }

  .noCertified {
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #fa522d;

    > svg {
      margin-right: 4px;
    }
  }
}

.userMenus {
  border-top: 1px solid #edeef2;
  color: #1e2945;

  > a {
    display: block;
    padding: 0 16px;
    font-size: 14px;
    line-height: 52px;
    cursor: pointer;
    color: #1e2945;

    &:hover {
      color: #1966ff;
    }
  }
}
