@import "../../../../../node_modules/@ecom/open-components/src/styles/variable.less";
@import "../../../../../node_modules/@ecom/open-components/src/styles/mixin.less";
.footer {
  width: 100%;
  height: 176px;
  background: #141f33;
  display: flex;
  justify-content: center;
  align-items: center;

  .alink {
    color: #aaabaf;
  }
}

.contentContainer {
  width: 1200px;
  display: flex;
  align-items: center;
}

.guideContainer {
  flex: 1 1 724px;
}

.logoWrapper {

  >img {
    width: 210px;
    height: 36px;
  }
}

.logoIcon {
  width: 221px;
  height: 32px;
}

.linkList {
  margin-top: 30px;
  font-size: 12px;

  >span:not(:first-child) {
    margin-left: 24px;
  }
}

.serviceContainer {
  flex-basis: 440px;
  display: flex;
  justify-content: space-between;
}

.cates {

  .title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    margin-bottom: 16px;
  }

  .subTitle {
    font-size: 12px;
    line-height: 16px;

    >p {
      margin-bottom: 10px;
    }
  }
}

.ISPN {
  color: hsla(0, 0%, 100%, .3);
  font-size: 12px;

  a {
    color: hsla(0, 0%, 100%, .3);
  }

  margin-top: 12px;
}
